import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.primary.main,
    '& p': {
      fontWeight: 400,
      marginBottom: 20,
    },
    '& h2': {
      fontSize: '1.2rem',
    },
  },
  box: {
    flex: '1 1',
    width: '95%',
  },
}));

function ContentContainer({
  description,
  vision,
  mission,
  missionObject,
  descriptionObject,
}) {
  const classes = useStyles();
  const visionArray = vision.split('*/');
  return (
    <Grid className={classes.container} container direction="column">
      {descriptionObject ? (
        <Paragraph {...descriptionObject} />
      ) : (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: description }}
        ></Typography>
      )}
      <Typography variant="h2" fontWeight={700}>
        VISION
      </Typography>
      {visionArray.map((v, idx) => (
        <Typography key={idx} variant="body1">
          {v}
        </Typography>
      ))}

      <Typography variant="h2" fontWeight={700}>
        MISSION
      </Typography>
      {missionObject ? (
        <ParagraphContainer contents={missionObject} />
      ) : (
        <Typography variant="body1">{mission}</Typography>
      )}
    </Grid>
  );
}

const ParagraphContainer = ({ contents }) => {
  return (
    <>
      {contents?.map((content, idx) => (
        <Paragraph key={idx} {...content} />
      ))}
    </>
  );
};

const Paragraph = ({ title, content }) => {
  return (
    <ul style={{ paddingLeft: 0 }}>
      <Typography variant="body1">{title}</Typography>
      {content.map((text, idx) => (
        <li key={idx}>{text}</li>
      ))}
    </ul>
  );
};
export default ContentContainer;
