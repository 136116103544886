import nominaGenGrazianoPresidente from '../../../../assets/documents/nomina_gen_graziano_presidente.pdf';
import comunicatoStampaAssonave2023 from '../../../../assets/documents/comunicato_stampa_assonave_2023.pdf';
import comunicatoStampaAssonave20231 from '../../../../assets/documents/comunicato_stampa_assonave_2023_1.pdf';
import pressReleaseAssonave2023 from '../../../../assets/documents/press_release_assonave_2023.pdf';
import pressReleaseAssonave20231 from '../../../../assets/documents/press_release_assonave_2023_1.pdf';
import comunicatoStampaAssonave2024 from '../../../../assets/documents/Assonave_Comunicato_Stampa_Gennaio24.pdf';
import comunicatoStampaAssonaveGiugno2024 from '../../../../assets/documents/Comunicato_Stampa_Assonave_Giugno_2024.pdf';
import comunicatoStampaAssonaveLuglio2024 from '../../../../assets/documents/Comunicato Stampa Assemblea Generale ASSONAVE 2024_1.pdf';

const getDocumentsDataIt = () => {
  const documents = [
    {
      title: 'Comunicato Stampa Assemblea Assonave 2024',
      url: comunicatoStampaAssonaveLuglio2024,
    },
    {
      title: 'Scomparsa del Presidente Claudio Graziano',
      url: comunicatoStampaAssonaveGiugno2024,
    },
    {
      title: 'Nomina Gen. Graziano Presidente',
      url: nominaGenGrazianoPresidente,
    },
    {
      title: 'Comunicato stampa Assonave Gennaio 2024',
      url: comunicatoStampaAssonave2024,
    },
    {
      title: 'Comunicato stampa Assonave 2023',
      url: comunicatoStampaAssonave2023,
    },
    {
      title: "Comunicato stampa Assonave 2023 dell'Assemblea dei Soci",
      url: comunicatoStampaAssonave20231,
    },
  ];
  return documents;
};
const getDocumentsDataEn = () => {
  const documents = [
    {
      title: 'Nomina Gen. Graziano Presidente',
      url: nominaGenGrazianoPresidente,
    },
    {
      title: 'Press release Assonave 2023',
      url: pressReleaseAssonave2023,
    },
    {
      title: 'Press release Assonave AGM 2023',
      url: pressReleaseAssonave20231,
    },
    {
      title: 'Comunicato stampa Assonave Gennaio 2024',
      url: comunicatoStampaAssonave2024,
    },
  ];
  return documents;
};

const getDocumentsData = (language) => {
  if (language === 'en') {
    return getDocumentsDataEn();
  }

  return getDocumentsDataIt();
};
export { getDocumentsDataIt, getDocumentsDataEn };
export default getDocumentsData;
