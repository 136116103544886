import baglietto_logo from '../../assets/img/members/baglietto_logo.png';
import baglietto_header from '../../assets/img/members/baglietto_header.jpg';

import ecospray_logo from '../../assets/img/members/ecospray_logo.png';
import ecospray_header from '../../assets/img/members/ecospray_header.png';

import elettronica_logo from '../../assets/img/members/elettronica_logo.jpg';
import elettronica_header from '../../assets/img/members/elettronica_header.jpg';

import fagioli_logo from '../../assets/img/members/fagioli_logo.jpg';
import fagioli_header from '../../assets/img/members/fagioli_header.jpg';

import femicz_logo from '../../assets/img/members/femicz_logo.png';
import femicz_header from '../../assets/img/members/femicz_header.jpg';

import quorum_logo from '../../assets/img/members/quorum_logo.jpg';
import quorum_header from '../../assets/img/members/quorum_header.JPG';

import fincantieri_next_logo from '../../assets/img/members/fincantieri_next_logo.png';
import fincantieri_next_header from '../../assets/img/members/fincantieri_next_header.JPG';

import ghisalba_logo from '../../assets/img/members/ghisalba_logo.jpg';
import ghisalba_header from '../../assets/img/members/ghisalba_header.jpg';

import idal_logo from '../../assets/img/members/idal_logo.png';
import idal_header from '../../assets/img/members/idal_header.jpg';

import imesa_logo from '../../assets/img/members/imesa_logo.jpg';
import imesa_header from '../../assets/img/members/imesa_header.jpg';

import medana_visca_logo from '../../assets/img/members/medana_visca_logo.png';
import medana_visca_header from '../../assets/img/members/medana_visca_header.png';

import metinvest_logo from '../../assets/img/members/metinvest_logo.png';
import metinvest_header from '../../assets/img/members/metinvest_header.jpg';

import garbarino_logo from '../../assets/img/members/garbarino_logo.jpg';
import garbarino_header from '../../assets/img/members/garbarino_header.jpg';

import rina_logo from '../../assets/img/members/rina_logo.png';
import rina_header from '../../assets/img/members/rina_header.jpg';

import wurth_logo from '../../assets/img/members/wurth_logo.png';
import wurth_header from '../../assets/img/members/wurth_header.png';

import mariotti_logo from '../../assets/img/members/mariotti_logo.png';
import mariotti_header from '../../assets/img/members/mariotti_header.jpg';

import sangiorgio_logo from '../../assets/img/members/sangiorgio_logo.png';
import sangiorgio_header from '../../assets/img/members/sangiorgio_header.png';

import canepa_logo from '../../assets/img/members/canepa_logo.jpeg';
import canepa_header from '../../assets/img/members/canepa_header.jpeg';

import palescandolo_logo from '../../assets/img/members/palescandolo_logo.png';
import palescandolo_header from '../../assets/img/members/palescandolo_header.jpeg';

import leonardo_logo from '../../assets/img/members/leonardo_logo.png';
import leonardo_header from '../../assets/img/members/leonardo_header.jpg';

import cofi_logo from '../../assets/img/members/cofi_logo.jpg';
import cofi_header from '../../assets/img/members/cofi_header.jpg';

import lagomarsino_logo from '../../assets/img/members/lagomarsino_logo.jpg';
import lagomarsino_header from '../../assets/img/members/lagomarsino_header.jpg';

import navim_logo from '../../assets/img/members/navim_logo.jpg';
import navim_header from '../../assets/img/members/navim_header.jpg';

import sgm_logo from '../../assets/img/members/sgm_logo.jpg';
import sgm_header from '../../assets/img/members/sgm_header.jpg';

import cetena_logo from '../../assets/img/members/cetena_logo.jpg';
import cetena_header from '../../assets/img/members/cetena_header.jpg';

import jotun_logo from '../../assets/img/members/jotun_logo.jpg';
import jotun_header from '../../assets/img/members/jotun_header.jpg';

import sirm_logo from '../../assets/img/members/sirm_logo.png';
import sirm_header from '../../assets/img/members/sirm_header.png';

import fincantieri_logo from '../../assets/img/members/fincantieri_logo.jpg';
import fincantieri_header from '../../assets/img/members/fincantieri_header.jpg';

import api_logo from '../../assets/img/members/api_logo.png';
import api_header from '../../assets/img/members/api_header.jpg';

import gaioni_logo from '../../assets/img/members/gaioni_logo.jpg';
import gaioni_header from '../../assets/img/members/gaioni_logo.jpg';

import johnson_logo from '../../assets/img/members/johnson_logo.jpg';
import johnson_header from '../../assets/img/members/johnson_header.jpg';

const shareholdersData = (t) => {
  const shareholders = {
    ordinary: [
      {
        id: 65,
        name: t('members:fincantieri.name'),
        description: t('members:fincantieri.description'),
        vision: t('members:fincantieri.vision'),
        mission: t('members:fincantieri.mission'),
        email: [],
        tel: '+39 040319 2111',
        address: 'Via Genova 1, 34121, Trieste',
        website: 'https://www.fincantieri.com',
        logo: fincantieri_logo,
        header: { image: fincantieri_header, text: 'fincantieri-header' },
        page: true,
      },
      {
        id: 62,
        name: t('members:cetena.name'),
        description: t('members:cetena.description'),
        vision: t('members:cetena.vision'),
        mission: t('members:cetena.mission'),
        email: ['Stefania.ricco@cetena.it'],
        tel: '+39 3316686466',
        address: 'Via Ippolito d’Aste 5, 16121 Genova',
        website: 'https://www.cetena.it',
        logo: cetena_logo,
        header: { image: cetena_header, text: 'cetena-header' },
        page: true,
      },
      {
        id: 64,
        name: t('members:elettronica.name'),
        description: t('members:elettronica.description'),
        vision: t('members:elettronica.vision'),
        mission: t('members:elettronica.mission'),
        email: ['Gioia.degennaro@elt.it', 'info@elt.it'],
        tel: '+39 064154254',
        address: 'Via Tiburtina Valeria km 13.700 Roma',
        website: 'https://www.elt-roma.com',
        logo: elettronica_logo,
        header: { image: elettronica_header, text: 'elettronica-header' },
        page: true,
      },
      {
        id: 66,
        name: 'ISOTTA FRASCHINI MOTORI S.p.A',
        link: 'https://www.isottafraschini.it/',
      },
      {
        id: 67,
        name: t('members:leonardo.name'),
        description: t('members:leonardo.description'),
        vision: t('members:leonardo.vision'),
        mission: t('members:leonardo.mission', { returnObjects: true }),
        email: ['leonardopressoffice@leonardo.com'],
        tel: ['+39 06 324731'],
        address: 'Piazza Monte Grappa, 4 - 00195 Roma',
        website: 'https://www.leonardo.com/it',
        logo: leonardo_logo,
        header: { image: leonardo_header, text: 'leonardo-header' },
        page: true,
      },
      {
        id: 68,
        name: t('members:rina.name'),
        description: t('members:rina.description'),
        vision: t('members:rina.vision'),
        mission: t('members:rina.mission', { returnObjects: true }),
        email: ['info@rina.org'],
        website: 'https://www.rina.org/it',
        tel: ['+39 010 53851'],
        address: 'Via Corsica 12, Genova',
        logo: rina_logo,
        header: { image: rina_header, text: 'rina-header' },
        page: true,
      },
      {
        id: 71,
        name: t('members:ecospray.name'),
        description: t('members:ecospray.description'),
        vision: t('members:ecospray.vision'),
        mission: t('members:ecospray.mission'),
        email: ['lossani@ecospray.eu'],
        website: 'https://ecospray.eu/it',
        tel: '+39 0131 854611',
        address: 'via Circonvallazione 14/A 15050 Alzano Scrivia (AL) - Italia',
        logo: ecospray_logo,
        header: { image: ecospray_header, text: 'ecospray-header' },
        page: true,
      },
      {
        id: 72,
        name: t('members:idal.name'),
        description: t('members:idal.description'),
        vision: t('members:idal.vision'),
        missionObject: t('members:idal.mission', { returnObjects: true }),
        email: ['info@idalgroup.it'],
        website: 'https://www.idalgroup.it/it',
        tel: ['+39 010 9863600', '+39 0187 980965'],
        address: 'Via Cupa delle Grotte, 2 Casola di Napoli (NA) 80050',
        logo: idal_logo,
        header: { image: idal_header, text: 'idal-header' },
        page: true,
      },
      {
        id: 73,
        name: 'Cantieri del Mediterraneo S.p.A',
        link: 'https://cantieridelmediterraneo.com/',
      },
      {
        id: 55,
        name: t('members:palescandolo.name'),
        descriptionObject: t('members:palescandolo.description', {
          returnObjects: true,
        }),
        vision: t('members:palescandolo.vision'),
        mission: t('members:palescandolo.mission'),
        email: ['info@centroservizinavali.com'],
        website: '',
        tel: ['+39 0431 624315'],
        address: 'VIA VOLTA, SNC, 33058, San Giorgio Di Nogaro, Udine',
        logo: palescandolo_logo,
        header: { image: palescandolo_header, text: 'palescandolo-header' },
        page: true,
      },
      {
        id: 54,
        name: t('members:wurth.name'),
        description: t('members:wurth.description'),
        vision: t('members:wurth.vision'),
        mission: t('members:wurth.mission', { returnObjects: true }),
        email: ['paolo.zappardino@wuerth.it'],
        website: 'https://fs.wuerth.it/it',
        tel: ['+39 3351823346'],
        address: 'Via Stazione 51, 39044 Egna, Bolzano',
        logo: wurth_logo,
        header: { image: wurth_header, text: 'wurth-header' },
        page: true,
      },
      {
        id: 74,
        name: 'SICOI',
        link: 'https://sicoi.com/',
      },
    ],
    aggregate: [
      {
        id: 0,
        name: t('members:api.name'),
        description: t('members:api.description'),
        vision: t('members:api.vision'),
        mission: t('members:api.mission'),
        email: ['marine@api-spa.com'],
        tel: '+39 010 7720751',
        address: 'via Trieste 13, 16018 Mignanego (Ge) Italy',
        logo: api_logo,
        header: { image: api_header, text: 'api-header' },
        page: true,
      },
      {
        id: 2,
        name: t('members:baglietto.name'),
        description: t('members:baglietto.description'),
        vision: t('members:baglietto.vision'),
        mission: t('members:baglietto.mission'),
        email: ['info@baglietto.eu'],
        website: 'https://www.baglietto.eu',
        tel: '+39 0143 318000',
        address: 'Via Castelgazzo 11, 15067 Novi Ligure Italia',
        logo: baglietto_logo,
        header: { image: baglietto_header, text: 'baglietto-header' },
        page: true,
      },
      {
        id: 3,
        name: 'Boldrocchi Srl',
        link: 'https://www.boldrocchigroup.com/',
      },
      {
        id: 4,
        name: t('members:canepa.name'),
        description: t('members:canepa.description'),
        vision: t('members:canepa.vision'),
        mission: t('members:canepa.mission'),
        email: ['rampi@canepaccampi.com'],
        website: 'https://canepaecampi.com',
        tel: '010 70261006',
        address: "Via A. Gramsci 4-6 16010 Sant'Olcese (GE)",
        logo: canepa_logo,
        header: { image: canepa_header, text: 'canepa-header' },
        page: true,
      },
      {
        id: 5,
        name: 'CCI Controlcavi Industria S.r.l / Cable Service S.r.l',
        link: 'https://www.cableservice.com/',
      },
      {
        id: 6,
        name: 'Chugoku-Boat Italy S.p.A',
        link: 'http://chugoku-boat.it/',
      },
      {
        id: 8,
        name: t('members:cofi.name'),
        description: t('members:cofi.description'),
        vision: t('members:cofi.vision'),
        mission: t('members:cofi.mission'),
        email: ['cofi@cofi.it'],
        website: 'https://www.cofi.it',
        tel: '39 0185284180',
        address: 'Via Fratelli Arpe 43, Santa Margherita Ligure (GE)',
        logo: cofi_logo,
        header: { image: cofi_header, text: 'cofi-header' },
        page: true,
      },
      {
        id: 9,
        name: 'Consilium Marine Italy S.r.l',
        link: 'https://www.consiliumsafety.com/en/',
      },
      { id: 11, name: 'Electronis S.r.l.', link: 'https://www.electronis.it/' },
      {
        id: 12,
        name: 'Elettrotecnica G.Pergolo Srl',
        link: 'https://www.elettrotecnicapergolo.com/index.php',
      },
      {
        id: 13,
        name: t('members:fagioli.name'),
        description: t('members:fagioli.description'),
        vision: t('members:fagioli.vision'),
        mission: t('members:fagioli.mission'),
        email: ['info@fagioli.com'],
        website: 'https://www.fagioli.com/it',
        tel: '+39 0522 6751',
        address: 'via G.B. Ferraris 13 – 42049 S. Ilario d’Enza (RE) - Italia',
        logo: fagioli_logo,
        header: { image: fagioli_header, text: 'fagioli-header' },
        page: true,
      },
      {
        id: 14,
        name: 'Fincantieri Marine Interiors S.p.A.',
        link: 'https://www.fincantieri.com/it/gruppo/controllate-collegate/marine-interiors/',
      },
      { id: 15, name: 'For M.A.' },
      {
        id: 16,
        name: t('members:gaioni.name'),
        description: t('members:gaioni.description'),
        vision: t('members:gaioni.vision'),
        mission: t('members:gaioni.mission', { returnObjects: true }),
        email: ['gaioni@gaioni.com'],
        website: 'https://www.gaioni.com/it/',
        tel: ['+39 010710041'],
        address: 'VIA MOLINASSI DI CERANESI 5A 16014 CERANESI (GENOVA)',
        logo: gaioni_logo,
        header: { image: gaioni_header, text: 'gaioni-header' },
        page: true,
      },
      { id: 17, name: 'Gamap S.r.l', link: 'https://www.gamap.it/' },
      {
        id: 18,
        name: t('members:garbarino.name'),
        description: t('members:garbarino.description'),
        vision: t('members:garbarino.vision'),
        mission: t('members:garbarino.mission', { returnObjects: true }),
        email: ['info@pompegarbarino.it'],
        website: 'http://www.pompegarbarino.com',
        tel: ['+39 0144 388671'],
        address: 'Via Marenco 44, 15011 Acqui Terme (AL), Italy',
        logo: garbarino_logo,
        header: { image: garbarino_header, text: 'garbarino-header' },
        page: true,
      },
      {
        id: 19,
        name: 'Generazione Vincente S.p.A.',
        link: 'https://www.generazionevincente.it/',
      },
      {
        id: 20,
        name: t('members:ghisalba.name'),
        description: t('members:ghisalba.description'),
        vision: t('members:ghisalba.vision'),
        mission: t('members:ghisalba.mission'),
        email: ['info@ghisalba.com'],
        website: 'http://www.ghisalba.com',
        tel: ['+39.02.3264.249/303'],
        address: 'Viale V. Lancetti, 43 - 20158 Milano',
        logo: ghisalba_logo,
        header: { image: ghisalba_header, text: 'ghisalba-header' },
        page: true,
      },
      { id: 21, name: 'Gimax S.r.l.', link: 'https://www.gimaxsrl.it/' },
      { id: 23, name: 'I V M S.r.l.', link: 'https://www.ivmsrl.it/' },
      {
        id: 24,
        name: t('members:imesa.name'),
        description: t('members:imesa.description'),
        vision: t('members:imesa.vision'),
        mission: t('members:imesa.mission'),
        email: ['info@imesa.it'],
        website: 'https://imesa.it',
        tel: ['+39 0421 468011'],
        address: 'VIA G. DI VITTORIO N.14, 60035 JESI (AN) - ITALIA',
        logo: imesa_logo,
        header: { image: imesa_header, text: 'imesa-header' },
        page: true,
      },
      {
        id: 26,
        name: t('members:lagomarsino.name'),
        description: t('members:lagomarsino.description'),
        vision: t('members:lagomarsino.vision'),
        mission: t('members:lagomarsino.mission'),
        email: ['info@lagomarsinoanielli.it'],
        website: 'https://www.lagomarsinoanielli.it',
        tel: ['+39 010 24 61 211'],
        address: 'Via dei Pescatori, snc – 16128 – Genova (Italy)',
        logo: lagomarsino_logo,
        header: { image: lagomarsino_header, text: 'lagomarsino-header' },
        page: true,
      },
      {
        id: 27,
        name: 'Lexacta Global Legal Advice Mapei S.p.A.',
        link: 'https://www.lexacta.it/',
      },
      {
        id: 28,
        name: 'Mapei S.p.A.',
        link: 'https://www.mapei.com/it/it/home-page',
      },
      { id: 29, name: 'Marinoni S.p.A', link: 'https://marinonispa.com/' },
      {
        id: 30,
        name: t('members:medana_visca.name'),
        description: t('members:medana_visca.description'),
        vision: t('members:medana_visca.vision'),
        mission: t('members:medana_visca.mission'),
        email: ['info@medanaevisca.it'],
        website: 'http://www.medanaevisca.it/index.php/it',
        tel: ['+39 0163 47777'],
        address: 'Regione Girussola 51, 13011 Borgosesia (VC)',
        logo: medana_visca_logo,
        header: { image: medana_visca_header, text: 'medana_visca-header' },
        page: true,
      },
      {
        id: 31,
        name: t('members:metinvest.name'),
        description: t('members:metinvest.description'),
        vision: t('members:metinvest.vision'),
        missionObject: t('members:metinvest.mission', { returnObjects: true }),
        email: ['sales@metinvest-westerneurope.com'],
        website: 'https://trametal.metinvestholding.com',
        tel: ['+39 010 5762911'],
        address: 'Via XII Ottobre 1, Genova, 16121, Italy',
        logo: metinvest_logo,
        header: { image: metinvest_header, text: 'metinvest-header' },
        page: true,
      },
      { id: 32, name: 'Molteni Spa', link: 'https://www.molteni.it/it/' },
      {
        id: 33,
        name: 'Monti & Barabino S.p.A',
        link: 'https://www.montiebarabino.com/',
      },
      {
        id: 34,
        name: 'Nidec ASI S.p.A. (ex Ansaldo Sistemi Industriali)',
        link: 'https://www.nidec-industrial.com/it',
      },
      {
        id: 36,
        name: 'Officine Nordest S.r.l.',
        link: 'https://officinenordest.it/',
      },
      {
        id: 37,
        name: 'O.M.C.I. - Aerotermica srl',
        link: 'http://www.omcisrl.it/',
      },
      {
        id: 38,
        name: 'PF Group S.r.l.',
        link: 'https://www.pulitecnicafriulana.it/',
      },
      {
        id: 39,
        name: 'Precetti S.r.l.',
        link: 'http://www.precettisrl.it/it/',
      },
      { id: 40, name: 'Savinox S.r.l.', link: 'http://www.savinox.it/' },
      {
        id: 41,
        name: 'Schneider Electric S.p.A',
        link: 'https://www.se.com/it/it/',
      },
      {
        id: 43,
        name: t('members:sgm.name'),
        description: t('members:sgm.description'),
        vision: t('members:sgm.vision'),
        mission: t('members:sgm.mission'),
        email: ['info@sgmsrl.eu'],
        website: 'https://www.sgmsrl.eu',
        tel: ['0432932828'],
        address: 'via Gorizia, 29 – 33040 – Visco (UD)',
        logo: sgm_logo,
        header: { image: sgm_header, text: 'sgm-header' },
        page: true,
      },
      { id: 45, name: 'Six Italia SpA', link: 'https://www.sixitalia.it/' },
      {
        id: 46,
        name: 'SOMEC Marine & Architectural Envelopes S.p.A',
        link: 'https://www.somecgruppo.com/',
      },
      {
        id: 47,
        name: 'Strutture S.r.l.',
        link: 'https://www.strutturesrl.com/',
      },
      { id: 48, name: 'Tech Marine S.r.l', link: 'http://www.techmarine.com/' },
      {
        id: 56,
        name: t('members:jotun.name'),
        description: t('members:jotun.description'),
        vision: t('members:jotun.mission'),
        mission: t('members:jotun.mission'),
        email: ['info@jotun.it'],
        website: 'https://www.jotun.com/',
        tel: ['+39 040 2398111'],
        address: 'via Petronio 8 34015 Muggia (TS) Italy ',
        logo: jotun_logo,
        header: { image: jotun_header, text: 'jotun-header' },
        page: true,
      },
      {
        id: 57,
        name: t('members:sirm.name'),
        description: t('members:sirm.description'),
        vision: t('members:sirm.mission'),
        mission: t('members:sirm.mission'),
        email: ['info@sirmitalia.it'],
        website: 'https://www.sirmitalia.it',
        tel: ['+39 081 5377 201'],
        address:
          'Calata San Marco 13 – 80143 Napoli  Headquarter:Via Terragneta 90 80058 Torre Annunziata',
        logo: sirm_logo,
        header: { image: sirm_header, text: 'sirm-header' },
        page: true,
      },
      {
        id: 58,
        name: t('members:fincantieri_next.name'),
        description: t('members:fincantieri_next.description'),
        vision: t('members:fincantieri_next.vision'),
        mission: t('members:fincantieri_next.mission'),
        email: ['segreteria@fincantierinxt.it'],
        tel: ['010 9863600', '0187 980965'],
        address: 'Via Carlo Ottavio Cornaggia, 10 – 20123 Milano',
        otherLocations: [
          "<b>Trieste</b>\nPasseggio Sant'Andrea\n6/a Trieste 34123 Italy",
          '<b>Noventa Padovana</b>\nVia della Navigazione\n109/A Noventa Padovana Padova 35027 Italy',
          '<b>Tortona</b>\nStrada Savonesa\n9 Tortona Alessandria 15057 Italy',
          '<b>Genoa</b>\nViale Brigate Partigiane\n92R Genoa 16129 Italy\nT: 010 9863600 / F: 010 9863678',
          '<b>La Spezia</b>\nVia San Bartolomeo\n446 La Spezia 19126 Italy',
          '<b>Follo</b>\nVia Trieste\n3 Follo La Spezia 19020 Italy\nT: 0187 980965 / F: 0187 981251',
          '<b>Pisa</b>\nVia Enrica Calabresi\n24 Pisa 56121 Italy',
          '<b>Rome</b>\nVia Carciano\n69 Rome 00131 Italy',
          '<b>Fiumicino</b>\nVia Portuense\n2482 Fiumicicno Rome 00054 Italy',
          '<b>Lecce</b>\nVia Arturo Maria Caprioli\n8/7 Lecce 73100 Italy',
        ],
        subsidiaries: [
          '<b>IDS Ingegneria Dei Sistemi SpA</b>\nVia Enrica Calabresi, 24\nPisa (PI) 56121 – Italy\nT. +39 050 3124 1 / F. +61 732 055536\nWeb site: <a href="https://www.idscorporation.com">https://www.idscorporation.com</a>',
          '<b>CETENA</b>\nVia Ippolito D’Aste, 5\n16121 Genova Italy\nT. +39 010 5965460 / F. +39 010 5965790\nWeb site: <a href="https://www.cetena.it">https://www.cetena.it</a>',
          '<b>ISSELNORD</b>\nVia Trieste, 4\n19020 Follo (SP) Italy\nT. +39 0187 941414 / F. +39 0187 941420\nWeb site: <a href="http://www.isselnord.it">http://www.isselnord.it</a>',
          '<b>E-PHORS</b>\nVia Carlo Ottavio Cornaggia, 10\n20123 Milan Italy',
          '<b>HMS IT SpA</b>\nVia Salvatore Quasimodo, 136\n00144 Rome (RM) Italy\nT. +39 06 72577100 / F. +39 06 72577499',
          '<b>ESSETI Srl</b>\nVia Portuense, 2482\n00054 Fiumicino (RM) Italy\nT. +39 06 97614061 / F. +39 06 97252020',
          '<b>SLS Srl</b>\n Via Montenero, 67\n00012 Guidonia Montecelio (RM) Italy\nT. +39 0774 572835 / F. +39 0774 572838',
          '<b>REICOM Srl</b>\nViale della Navigazione Interna, 109A\n35027 Noventa Padovana (PD) Italy\nT. +39 049 776345 / F. +39 0495207947',
        ],
        logo: fincantieri_next_logo,
        header: {
          image: fincantieri_next_header,
          text: 'fincantieri-next-header',
        },
        page: true,
      },
      {
        id: 61,
        name: t('members:johnson.name'),
        description: t('members:johnson.description'),
        vision: t('members:johnson.vision'),
        mission: t('members:johnson.mission'),
        email: ['marco.calculli@jci.com', 'be-italy-marcom@jci.com'],
        address: 'Via Milanese 124, 20092 – Cinisello Balsamo (MI)',
        logo: johnson_logo,
        header: { image: johnson_header, text: 'johnson-header' },
        page: true,
      },
      {
        id: 64,
        name: t('members:femicz.name'),
        description: t('members:femicz.description'),
        vision: t('members:femicz.vision'),
        mission: t('members:femicz.mission'),
        email: ['commerciale@femicz.it'],
        tel: '+39 0425 470711',
        address: 'Viale del Lavoro, 16 – 45100 Rovigo (RO)',
        website: 'https://www.femicz.it',
        logo: femicz_logo,
        header: { image: femicz_header, text: 'femi-cz-header' },
        page: true,
      },
      {
        id: 65,
        name: 'COSNAV Engineering S.r.l',
        link: 'https://cosnavsrl.it/',
      },
      {
        id: 199,
        name: t('members:quorum.name'),
        description: t('members:quorum.description'),
        vision: t('members:quorum.vision'),
        mission: t('members:quorum.mission'),
        email: ['acolantoni@quorumlegal.com'],
        tel: '0688819000 - +39 3355219348',
        address:
          'Via degli Scipioni n. 281 (Roma) – Via Cino del Duca n. 5 (Milano)',
        website: 'https://qltlex.com/',
        logo: quorum_logo,
        header: { image: quorum_header, text: 'quorum-header' },
        page: true,
      },
      {
        id: 200,
        name: 'SIPLES',
        link: 'https://www.siplessrl.it/index.htm',
      },
      {
        id: 201,
        name: 'TSNI',
      },
    ],
    grinav: [
      {
        id: 56,
        name: 'Fincantieri S.p.A Stabilimento di Palermo',
        link: 'https://www.fincantieri.com/it/',
      },
      {
        id: 57,
        name: t('members:mariotti.name'),
        description: t('members:mariotti.description'),
        vision: t('members:mariotti.vision'),
        mission: t('members:mariotti.mission', { returnObjects: true }),
        email: ['info@mariottiyard.it'],
        website: 'https://www.mariottiyard.it',
        tel: ['+39 010 24081'],
        address: 'Via dei Pescatori, 16128 Genova',
        logo: mariotti_logo,
        header: { image: mariotti_header, text: 'mariotti-header' },
        page: true,
      },
      {
        id: 59,
        name: t('members:navim.name'),
        description: t('members:navim.description'),
        vision: t('members:navim.vision'),
        mission: t('members:navim.mission', { returnObjects: true }),
        email: ['marketing@navim.com '],
        website: 'https://www.navim.com',
        tel: ['+39 010 73271'],
        address: 'Salita Guardia 60/A 16014 Ceranesi (GE)',
        logo: navim_logo,
        header: { image: navim_header, text: 'navim-header' },
        page: true,
      },
      { id: 60, name: 'Nuova Vernazza Ponteggi Navali S.r.l' },
      {
        id: 61,
        name: t('members:sangiorgio.name'),
        description: t('members:sangiorgio.description'),
        vision: t('members:sangiorgio.vision'),
        mission: t('members:sangiorgio.mission', { returnObjects: true }),
        email: ['press@sgdp.it'],
        website: 'https://www.sgdp.it',
        tel: ['+39 010 251561'],
        address: 'Calata Boccardo 8, 16128, Genoa, Italy',
        logo: sangiorgio_logo,
        header: { image: sangiorgio_header, text: 'sangiorgio-header' },
        page: true,
      },
    ],
  };
  return shareholders;
};

export const getShareHolder = (id, type, t) => {
  const shareholders: any = shareholdersData(t);
  const shareholder = shareholders[type]?.find(
    (shareholder) => shareholder.id === +id,
  );
  return shareholder;
};

export const getShareholderList = (t, type) => {
  const shareholders = shareholdersData(t);
  const sorted = shareholders[type].sort((prev, next) =>
    prev.name.toLowerCase() > next.name.toLowerCase() ? 1 : -1,
  );
  return sorted;
};
